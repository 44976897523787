.input {
  width: 100%;
  height: 30px;
  border: none;
  background-color: #fafafa;
  border-radius: 6px;
  margin-top: 6px;
}

.inputBlock {
  margin-top: 20px;
}

.button {
  font-size: 14px;
  background-color: rgb(230, 158, 0);
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 16px;
  text-decoration: none;
  transition: all 0.2s ease 0s;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  width: 100%;
  margin-top: 20px;
}

.button:hover {
  transform: scale(1.05);
  background-color: rgb(241, 166, 2);
}

.button:disabled {
  background-color: #dddddd;
  cursor: default;
  pointer-events: none;
}

.button:disabled:hover {
  background-color: #dddddd;
  transform: scale(1);
}
